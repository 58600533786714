import styled from 'styled-components';

import { media } from '../../utils/responsive';

const HeaderOffset = styled.div`
  margin-top: 102px;

  ${media.large`
    margin-top: 64px;
  `}
`;

export default HeaderOffset;
