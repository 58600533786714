import React from 'react';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

function SEO({ description, lang, meta, keywords, title, image, jsonLd }) {
  const location = useLocation();
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          keywords
          author
          siteUrl
          social {
            twitter
          }
        }
      }

      mainSocialImage: file(
        absolutePath: { regex: "/main-social-image.jpg/" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            src
          }
        }
      }
    }
  `);
  const metaDescription = description || data.site.siteMetadata.description;
  const metaKeywords = keywords || data.site.siteMetadata.keywords;
  const metaImage = image || data.mainSocialImage.childImageSharp.fluid.src;
  const siteUrl =
    typeof window !== 'undefined'
      ? location.origin
      : data.site.siteMetadata.siteUrl;
  const url = siteUrl + location.pathname;
  const jsonLdOptions = {
    '@context': 'https://schema.org',
    ...jsonLd,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      link={[{ rel: 'canonical', href: url }]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: siteUrl + metaImage,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: data.site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: siteUrl + metaImage,
        },
        {
          name: 'twitter:site',
          content: data.site.siteMetadata.social.twitter,
        },
        {
          name: 'twitter:creator',
          content: data.site.siteMetadata.social.twitter,
        },
      ].concat(meta)}
      script={[helmetJsonLdProp(jsonLdOptions)]}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: '',
  image: null,
  jsonLd: {},
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.string,
  title: PropTypes.string.isRequired,
  jsonLd: PropTypes.object,
};

export default SEO;
